import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroller";
import Link from "next/link";
import LoadingBubbles from "shared/components/loading-bubbles";
import PropertyCard from "shared/components/property-card";
import { MessageResults } from "shared/components/messages-result";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import ContactDialog from "shared/components/contact-dialog";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { IRealestateResponseData } from "@interfaces/response/realestate.interface";
import useMediaQuery from "shared/hooks/use-media-query";

function PropertyList(props: any) {
  const { isMobile } = useMediaQuery();
  const [open, setOpen] = useState<boolean>(false);
  const ref = useGetPersistParams();
  const translation = useTranslations("buy-sell-filter.noResult");
  const router = useRouter();
  // const { loadingRequest, setLoadingPropertyAction, clearPropertyAction } =
  //   usePropertiesLoading();
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    getMore,
    hasNextPage,
    data,
    isError,
    isSuccess,
    detailPath,
    isLoading,
  } = props;

  // set scroll restoration to manual
  useEffect(() => {
    if (
      "scrollRestoration" in history &&
      history.scrollRestoration !== "manual"
    ) {
      history.scrollRestoration = "manual";
    }
  }, []);

  // handle and store scroll position
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      //if going to a property, save the position and path
      if (
        url.startsWith("/propiedad/") ||
        url.startsWith("/collection/propiedad/")
      ) {
        sessionStorage.setItem("scrollPosition", window.scrollY.toString());
        sessionStorage.setItem(
          "scrollPositionPath",
          location.pathname + location.search
        );
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  // restore scroll position
  useEffect(() => {
    if (scrollRef.current && "scrollPosition" in sessionStorage) {
      const prevPath = sessionStorage.getItem("prevPath");
      const scrollPositionPath =
        sessionStorage.getItem("scrollPositionPath") ?? "";

      //restore position if coming from a property
      //and the target is the same path
      if (
        (prevPath?.startsWith("/propiedad/") ||
          prevPath?.startsWith("/collection/propiedad/")) &&
        router.asPath === scrollPositionPath
      ) {
        window.scrollTo(0, Number(sessionStorage.getItem("scrollPosition")));
      }

      sessionStorage.removeItem("scrollPosition");
      sessionStorage.removeItem("scrollPositionPath");
    }
  }, []);

  if (isLoading && data?.pages[0]?.data.length === undefined) {
    return (
      <SearchResults>
        <LoadingBubbles />
      </SearchResults>
    );
  }

  if (isSuccess && data?.pages[0]?.data.length <= 0) {
    return (
      <SearchResults>
        <MessageResults
          message={translation("textContent")}
          modalComponent={
            <ModalBtn onClick={handleOpen}>{translation("textBoton")}</ModalBtn>
          }
        />
        <ContactDialog
          open={open}
          onClose={handleClose}
          classNameModalButton="enviar-formulario-contacto-menu"
        />
      </SearchResults>
    );
  }

  if (isError) {
    return (
      <SearchResults>
        <MessageResults message="Ha ocurrido un error" />
      </SearchResults>
    );
  }

  const getUrls = (item: IRealestateResponseData) => {
    const url = detailPath(item) as string;
    if (ref) {
      return `${url}${ref}&city=${item?.city
        ?.replaceAll(" ", "-")
        ?.toLowerCase()}${isMobile ? "&mobile=true" : ""}`;
    }
    return `${url}?city=${item?.city?.replaceAll(" ", "-")?.toLowerCase()}${
      isMobile ? "&mobile=true" : ""
    }`;
  };

  const handleChangePage = (page: number) => {
    getMore(page);
  };

  return (
    <div ref={scrollRef}>
      <InfiniteScroll
        loadMore={handleChangePage}
        hasMore={hasNextPage}
        loader={<LoadingBubbles />}
      >
        <SearchContentResults>
          {data?.pages.map((page: { data: IRealestateResponseData[] }) =>
            page.data.map((item) => (
              <Link
                key={item.id}
                href={getUrls(item)}
                passHref
                target={!isMobile ? "_blank" : "_self"}
              >
                <a target={!isMobile ? "_blank" : "_self"}>
                  <StyledPropertyCard {...item} />
                </a>
              </Link>
            ))
          )}
        </SearchContentResults>
      </InfiniteScroll>
    </div>
  );
}

export default PropertyList;

const StyledPropertyCard = styled(PropertyCard)`
  @media screen and (max-width: 500px) {
    width: 315px;
  }
`;

const SearchContentResults = styled.div`
  display: grid;
  justify-items: center;
  justify-content: space-between;
  grid-template-columns:
    calc(24.75% - 6px) calc(24.75% - 6px) calc(24.75% - 6px)
    calc(24.75% - 6px);
  gap: 30px 12px;

  @media screen and (max-width: 1299px) {
    grid-template-columns: calc(33.333% - 6px) calc(33.333% - 6px) calc(
        33.333% - 6px
      );
    gap: 30px 10px;
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    gap: 30px 10px;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 100%;
    gap: 30px 0px;
  }
`;

const SearchResults = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ModalBtn = styled.a`
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
  color: var(--color-secondary);
  text-decoration: underline;
`;
